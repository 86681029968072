"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serializeConfigureBakerPayload = exports.serializeConfigureDelegationPayload = exports.getSerializedConfigureDelegationBitmap = exports.configureDelegationSerializationSpec = exports.orUndefined = exports.isDefined = exports.serializeYearMonth = exports.serializeVerifyKey = exports.encodeHexString = exports.packBufferWithWord16Length = exports.packBufferWithWord32Length = exports.encodeDataBlob = exports.encodeWord16FromString = exports.encodeWord8FromString = exports.encodeWord8 = exports.encodeInt8 = exports.encodeWord16 = exports.encodeInt16 = exports.encodeWord32 = exports.encodeInt32 = exports.encodeWord64 = exports.encodeBool = exports.serializeList = exports.serializeMap = void 0;
const buffer_1 = require("buffer/");
const types_1 = require("./types");
function serializeMap(map, encodeSize, encodeKey, encodeValue) {
    const keys = Object.keys(map);
    const buffers = [encodeSize(keys.length)];
    keys.forEach((key) => {
        buffers.push(encodeKey(key));
        buffers.push(encodeValue(map[key]));
    });
    return buffer_1.Buffer.concat(buffers);
}
exports.serializeMap = serializeMap;
function serializeList(list, putSize, putMember) {
    const buffers = [putSize(list.length)];
    list.forEach((member) => {
        buffers.push(putMember(member));
    });
    return buffer_1.Buffer.concat(buffers);
}
exports.serializeList = serializeList;
/**
 * Encodes a boolean to a Buffer using big endian.
 * @param value a boolean value
 * @returns boolean serialization of the input
 */
function encodeBool(value) {
    const result = value === true ? 1 : 0;
    const arr = new ArrayBuffer(1);
    const view = new DataView(arr);
    view.setInt8(0, result);
    return buffer_1.Buffer.from(new Int8Array(arr));
}
exports.encodeBool = encodeBool;
/**
 * Encodes a 64 bit unsigned integer to a Buffer using big endian.
 * @param value a 64 bit integer
 * @param useLittleEndian a boolean value, if not given, the value is serialized in big endian.
 * @returns big endian serialization of the input
 */
function encodeWord64(value, useLittleEndian = false) {
    if (value > 18446744073709551615n || value < 0n) {
        throw new Error('The input has to be a 64 bit unsigned integer but it was: ' + value);
    }
    const arr = new ArrayBuffer(8);
    const view = new DataView(arr);
    view.setBigUint64(0, value, useLittleEndian);
    return buffer_1.Buffer.from(new Uint8Array(arr));
}
exports.encodeWord64 = encodeWord64;
/**
 * Encodes a 32 bit signed integer to a Buffer using big endian.
 * @param value a 32 bit integer
 * @param useLittleEndian a boolean value, if not given, the value is serialized in big endian.
 * @returns big endian serialization of the input
 */
function encodeInt32(value, useLittleEndian = false) {
    if (value < -2147483648 || value > 2147483647 || !Number.isInteger(value)) {
        throw new Error('The input has to be a 32 bit signed integer but it was: ' + value);
    }
    const arr = new ArrayBuffer(4);
    const view = new DataView(arr);
    view.setInt32(0, value, useLittleEndian);
    return buffer_1.Buffer.from(new Int8Array(arr));
}
exports.encodeInt32 = encodeInt32;
/**
 * Encodes a 32 bit unsigned integer to a Buffer.
 * @param value a 32 bit integer
 * @param useLittleEndian a boolean value, if not given, the value is serialized in big endian.
 * @returns big endian serialization of the input
 */
function encodeWord32(value, useLittleEndian = false) {
    if (value > 4294967295 || value < 0 || !Number.isInteger(value)) {
        throw new Error('The input has to be a 32 bit unsigned integer but it was: ' + value);
    }
    const arr = new ArrayBuffer(4);
    const view = new DataView(arr);
    view.setUint32(0, value, useLittleEndian);
    return buffer_1.Buffer.from(new Uint8Array(arr));
}
exports.encodeWord32 = encodeWord32;
/**
 * Encodes a 16 bit signed integer to a Buffer.
 * @param value a 16 bit integer
 * @param useLittleEndian a boolean value, if not given, the value is serialized in big endian.
 * @returns big endian serialization of the input
 */
function encodeInt16(value, useLittleEndian = false) {
    if (value < -32768 || value > 32767 || !Number.isInteger(value)) {
        throw new Error('The input has to be a 16 bit signed integer but it was: ' + value);
    }
    const arr = new ArrayBuffer(2);
    const view = new DataView(arr);
    view.setInt16(0, value, useLittleEndian);
    return buffer_1.Buffer.from(new Int8Array(arr));
}
exports.encodeInt16 = encodeInt16;
/**
 * Encodes a 16 bit unsigned integer to a Buffer using big endian.
 * @param value a 16 bit integer
 * @param useLittleEndian a boolean value, if not given, the value is serialized in big endian.
 * @returns big endian serialization of the input
 */
function encodeWord16(value, useLittleEndian = false) {
    if (value > 65535 || value < 0 || !Number.isInteger(value)) {
        throw new Error('The input has to be a 16 bit unsigned integer but it was: ' + value);
    }
    const arr = new ArrayBuffer(2);
    const view = new DataView(arr);
    view.setUint16(0, value, useLittleEndian);
    return buffer_1.Buffer.from(new Uint8Array(arr));
}
exports.encodeWord16 = encodeWord16;
/**
 * Encodes a 8 bit signed integer to a Buffer using big endian.
 * @param value a 8 bit integer
 * @returns big endian serialization of the input
 */
function encodeInt8(value) {
    if (value > 127 || value < -128 || !Number.isInteger(value)) {
        throw new Error('The input has to be a 8 bit signed integer but it was: ' + value);
    }
    return buffer_1.Buffer.from(buffer_1.Buffer.of(value));
}
exports.encodeInt8 = encodeInt8;
/**
 * Encodes a 8 bit unsigned integer to a Buffer using big endian.
 * @param value a 8 bit integer
 * @returns big endian serialization of the input
 */
function encodeWord8(value) {
    if (value > 255 || value < 0 || !Number.isInteger(value)) {
        throw new Error('The input has to be a 8 bit unsigned integer but it was: ' + value);
    }
    return buffer_1.Buffer.from(buffer_1.Buffer.of(value));
}
exports.encodeWord8 = encodeWord8;
function encodeWord8FromString(value) {
    return encodeWord8(Number(value));
}
exports.encodeWord8FromString = encodeWord8FromString;
function encodeWord16FromString(value, useLittleEndian = false) {
    return encodeWord16(Number(value), useLittleEndian);
}
exports.encodeWord16FromString = encodeWord16FromString;
/**
 * Encodes a Datablob.
 * @param blob Datablob containing data bytes.
 * @returns Buffer containing the length of the data and the data bytes.
 */
function encodeDataBlob(blob) {
    const length = encodeWord16(blob.data.length);
    return buffer_1.Buffer.concat([length, blob.data]);
}
exports.encodeDataBlob = encodeDataBlob;
/**
 * Packing a buffer along with its length in 32 bits
 * @param buffer
 * @param useLittleEndian a boolean value, if not given, the value is serialized in big endian.
 * @returns Buffer containing the 32 bit length of buffer and buffer.
 */
function packBufferWithWord32Length(buffer, useLittleEndian = false) {
    const length = encodeWord32(buffer.length, useLittleEndian);
    return buffer_1.Buffer.concat([length, buffer]);
}
exports.packBufferWithWord32Length = packBufferWithWord32Length;
/**
 * Packing a buffer along the with offset of 16 bit length
 * @param buffer containing the buffer
 * @returns Buffer containing the length of the buffer of 16 bit and buffer.
 */
function packBufferWithWord16Length(buffer) {
    const length = encodeWord16(buffer.length);
    return buffer_1.Buffer.concat([length, buffer]);
}
exports.packBufferWithWord16Length = packBufferWithWord16Length;
/**
 * Convert a hex string to a Buffer
 * @param str hex-encoded string
 * @returns Buffer
 */
function encodeHexString(s) {
    return buffer_1.Buffer.from(s, 'hex');
}
exports.encodeHexString = encodeHexString;
var SchemeId;
(function (SchemeId) {
    SchemeId[SchemeId["Ed25519"] = 0] = "Ed25519";
})(SchemeId || (SchemeId = {}));
/**
 * Serializes a public key. The serialization includes the
 * scheme used for the key/
 * @param key the key to serialize
 * @returns the serialization of the key
 */
function serializeVerifyKey(key) {
    const scheme = key.schemeId;
    let schemeId;
    if (SchemeId[scheme] !== undefined) {
        schemeId = SchemeId[scheme];
    }
    else {
        throw new Error(`Unknown key type: ${scheme}`);
    }
    const keyBuffer = buffer_1.Buffer.from(key.verifyKey, 'hex');
    const serializedScheme = encodeWord8(schemeId);
    return buffer_1.Buffer.concat([serializedScheme, keyBuffer]);
}
exports.serializeVerifyKey = serializeVerifyKey;
/**
 * Serializes a year and month string.
 * @param yearMonth year and month formatted as "YYYYMM"
 * @returns the serialization of the year and month string
 */
function serializeYearMonth(yearMonth) {
    const year = parseInt(yearMonth.substring(0, 4), 10);
    const month = parseInt(yearMonth.substring(4, 6), 10);
    const serializedYear = encodeWord16(year);
    const serializedMonth = encodeWord8(month);
    return buffer_1.Buffer.concat([serializedYear, serializedMonth]);
}
exports.serializeYearMonth = serializeYearMonth;
/**
 * Makes a bitmap for transactions with optional payload fields, where each bit indicates whether a value is included or not.
 *
 * @param payload the payload to generate the bitmap for
 * @param fieldOrder the order the payload fields are serialized in. The order is represented in the bitmap from right to left, i.e index 0 of the order translates to first bit.
 *
 * @example
 * getPayloadBitmap<{test?: string; test2?: string}>({test2: 'yes'}, ['test', 'test2']) // returns 2 (00000010 as bits of UInt8)
 * getPayloadBitmap<{test?: string; test2?: string; test3?: number}>({test: 'yes', test3: 100}, ['test', 'test2', 'test3']) // returns 5 (00000101 as bits of UInt8)
 */
function getPayloadBitmap(payload, fieldOrder) {
    return fieldOrder
        .map((k) => payload[k])
        .reduceRight(
    // eslint-disable-next-line no-bitwise
    (acc, cur) => (acc << 1) | Number(cur !== undefined), 0);
}
function isDefined(v) {
    return v !== undefined;
}
exports.isDefined = isDefined;
/**
 * Given a specification describing how to serialize the fields of a payload of type T, this function produces a function
 * that serializes payloads of type T, returning a buffer of the serialized fields by order of occurance in serialization spec.
 */
const serializeFromSpec = (spec) => (payload) => {
    const buffers = Object.keys(spec)
        .map((k) => {
        const v = payload[k];
        const f = spec[k];
        return f(v);
    })
        .filter(isDefined);
    return buffer_1.Buffer.concat(buffers);
};
/**
 * Takes a callback function taking 1 argument, returning a new function taking same argument, applying callback only if supplied argument is defined.
 */
const orUndefined = (fun) => (v) => v !== undefined ? fun(v) : undefined;
exports.orUndefined = orUndefined;
function serializeDelegationTarget(target) {
    if (target.delegateType === types_1.DelegationTargetType.PassiveDelegation) {
        return encodeInt8(0);
    }
    else {
        return buffer_1.Buffer.concat([encodeInt8(1), encodeWord64(target.bakerId)]);
    }
}
exports.configureDelegationSerializationSpec = {
    stake: (0, exports.orUndefined)((x) => encodeWord64(x.microCcdAmount)),
    restakeEarnings: (0, exports.orUndefined)(encodeBool),
    delegationTarget: (0, exports.orUndefined)(serializeDelegationTarget),
};
const getSerializedConfigureDelegationBitmap = (payload) => encodeWord16(getPayloadBitmap(payload, Object.keys(exports.configureDelegationSerializationSpec)));
exports.getSerializedConfigureDelegationBitmap = getSerializedConfigureDelegationBitmap;
function serializeConfigureDelegationPayload(payload) {
    const bitmap = (0, exports.getSerializedConfigureDelegationBitmap)(payload);
    const serializedPayload = serializeFromSpec(exports.configureDelegationSerializationSpec)(payload);
    return buffer_1.Buffer.concat([bitmap, serializedPayload]);
}
exports.serializeConfigureDelegationPayload = serializeConfigureDelegationPayload;
const serializeVerifyKeys = serializeFromSpec({
    electionVerifyKey: encodeHexString,
    proofElection: encodeHexString,
    signatureVerifyKey: encodeHexString,
    proofSig: encodeHexString,
    aggregationVerifyKey: encodeHexString,
    proofAggregation: encodeHexString,
});
const serializeUrl = (url) => {
    const data = buffer_1.Buffer.from(new TextEncoder().encode(url));
    const length = encodeWord16(data.length);
    return buffer_1.Buffer.concat([length, data]);
};
const configureBakerSerializationSpec = {
    stake: (0, exports.orUndefined)((v) => encodeWord64(v.microCcdAmount)),
    restakeEarnings: (0, exports.orUndefined)(encodeBool),
    openForDelegation: (0, exports.orUndefined)(encodeWord8),
    keys: (0, exports.orUndefined)(serializeVerifyKeys),
    metadataUrl: (0, exports.orUndefined)(serializeUrl),
    transactionFeeCommission: (0, exports.orUndefined)(encodeWord32),
    bakingRewardCommission: (0, exports.orUndefined)(encodeWord32),
    finalizationRewardCommission: (0, exports.orUndefined)(encodeWord32),
};
const getSerializedConfigureBakerBitmap = (payload) => encodeWord16(getPayloadBitmap(payload, Object.keys(configureBakerSerializationSpec)));
function serializeConfigureBakerPayload(payload) {
    const bitmap = getSerializedConfigureBakerBitmap(payload);
    const serializedPayload = serializeFromSpec(configureBakerSerializationSpec)(payload);
    return buffer_1.Buffer.concat([bitmap, serializedPayload]);
}
exports.serializeConfigureBakerPayload = serializeConfigureBakerPayload;
